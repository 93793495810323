import React, { FC, useState } from 'react';
import Col from 'react-bootstrap/Col';
import YouTube from 'react-youtube';
import classNames from 'classnames';

import Image from 'common/Image';

import { ListingCardProps } from '../models';
import { youtubeOpts } from './youtubeSettings';

const ContentCard: FC<ListingCardProps> = (props) => {
  const [isOpenAccordion, setIsOpenAccordion] = useState<boolean>(false);
  const { title, description, type, ctaLabel, image, subDescription, videoLink } = props;

  const manageAccordionState = () => setIsOpenAccordion((prev) => !prev);

  return (
    <Col
      as="li"
      md={6}
      sm={12}
      className={classNames('listing-card-wrapper', {
        [`listing-card-wrapper--open`]: isOpenAccordion,
      })}
      data-testid="listing-card"
    >
      <div className={`listing-card listing-card--${type}`}>
        <h3 className="listing-card__title">{title}</h3>
        <div className="listing-card__container">
          <p className="listing-card__description">{description}</p>
          {subDescription ? (
            <article
              className={classNames('listing-card__content', {
                [`listing-card__content--open`]: isOpenAccordion,
              })}
            >
              <p className="listing-card__description">{subDescription}</p>
            </article>
          ) : null}
          <button
            className={classNames('listing-card__cta', {
              [`listing-card__cta--open`]: isOpenAccordion,
            })}
            onClick={manageAccordionState}
            tabIndex={0}
            type="button"
          >
            {ctaLabel}
          </button>
          {image || videoLink ? (
            <div
              className={classNames('listing-card__media', {
                [`listing-card__media--open`]: isOpenAccordion,
              })}
            >
              {image ? (
                <Image imageData={image} alt={title || ctaLabel} className="listing-card__image" />
              ) : null}
              {videoLink ? (
                <YouTube className="listing-card__video" videoId={videoLink} opts={youtubeOpts} />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </Col>
  );
};

export default ContentCard;
