import React, { FC, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classNames from 'classnames';

import Button, { VisualType, VisualVariant } from 'common/Button';
import CarouselWrapper from 'common/CustomCarousel';
import LinkButton from 'components/LinkButton';

import { ListingType } from './constants';
import ListingCard from './ListingCard';
import ListingDescription from './ListingDescription';
import { BaseListingProps } from './models';

import './Listing.scss';
import './ShadowListing.scss';

export const BaseListing: FC<BaseListingProps> = ({
  type,
  title,
  description,
  cta: [cta],
  cardsInRow,
  cards,
  loadMore,
  ctaAriaLabel,
  showAllAriaLabel,
  findCallOutTag,
  fullWidthDescription,
  useExternalLinks,
  fullDescription = false,
}) => {
  const [cardsCount, setCardsCount] = useState<number | undefined>(
    loadMore ? loadMore.startItems : undefined
  );
  const [showLoadMore, setShowLoadMore] = useState(loadMore && cards.length > loadMore.startItems);

  const onLoadMoreClick = () => {
    const newCount = cardsCount! + loadMore!.loadItems;
    setCardsCount(newCount);
    if (newCount >= cards.length) {
      setShowLoadMore(false);
    }
  };

  return (
    <Row className={`listing listing--${type}`} data-testid="base-listing">
      <Col className="listing__col">
        {title ? <h2 className="listing__title">{title}</h2> : null}
        {description ? (
          <ListingDescription
            description={description}
            fullWidthDescription={fullWidthDescription}
          />
        ) : null}
        {type === ListingType.ArticleCampaign ? (
          <CarouselWrapper threeCardsInRow>
            {cards.slice(0, cardsCount).map((card) => (
              <ListingCard
                key={card.url || card.title}
                {...{
                  ...card,
                  cardsInRow,
                  type,
                  findCallOutTag,
                  useExternalLinks,
                  fullDescription,
                }}
              />
            ))}
          </CarouselWrapper>
        ) : (
          <Row
            as="ul"
            className={classNames('listing__cards', {
              'listing__cards--negate-top': !title && !description,
              'listing__cards--negate-bottom': !cta && !showLoadMore,
            })}
          >
            {cards.slice(0, cardsCount).map((card) => (
              <ListingCard
                key={card.url || card.title}
                {...{
                  ...card,
                  cardsInRow,
                  type,
                  findCallOutTag,
                  useExternalLinks,
                  fullDescription,
                }}
              />
            ))}
          </Row>
        )}
        {showLoadMore ? (
          <div className="listing__load-more">
            <Button
              visualType={VisualType.Discover}
              visualVariant={VisualVariant.Dark}
              onClick={onLoadMoreClick}
              ariaLabel={showAllAriaLabel || undefined}
            >
              {loadMore!.label}
            </Button>
          </div>
        ) : null}
        {cta ? (
          <div className="listing__cta">
            <LinkButton
              text={cta.name}
              url={cta.url}
              ariaLabel={ctaAriaLabel}
              type={VisualType.Discover}
              variant={VisualVariant.Dark}
            />
          </div>
        ) : null}
      </Col>
    </Row>
  );
};

export default BaseListing;
