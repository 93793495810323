// Based on react-multi-carousel WithScrollbar story: https://github.com/YIZHUANG/react-multi-carousel/blob/master/stories/WithScrollbar.js
import React, { FC, useEffect, useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import classNames from 'classnames';

import { DEFAULT_SM_PADDINGS, RESPONSIVE, RESPONSIVETHREE } from './constants';
import CarouselControls from './Controls';
import { CarouselProps } from './models';
import useAdditionalTransform from './useAdditionalTransform';

import 'react-multi-carousel/lib/styles.css';
import './Carousel.scss';

const CarouselWrapper: FC<CarouselProps> = ({
  children,
  threeCardsInRow,
  smPaddings = DEFAULT_SM_PADDINGS,
}) => {
  const carousel = useRef<Carousel>(null);
  const [isCarouselMoving, setIsCarouselMoving] = useState(false);

  const { additionalTransfrom, updateAdditionalTransfrom } = useAdditionalTransform(
    carousel,
    smPaddings
  );

  useEffect(() => {
    updateAdditionalTransfrom();

    const updateListener = () => updateAdditionalTransfrom();
    window.addEventListener('resize', updateListener);

    return () => window.removeEventListener('resize', updateListener);
  }, [updateAdditionalTransfrom]);

  const onChildFocus = (childIndex: number) => {
    if (!carousel.current?.state) {
      return;
    }

    const { slidesToShow, currentSlide, totalItems } = carousel.current.state;

    if (slidesToShow > totalItems) {
      return;
    }

    const nextSlide = Math.floor(childIndex / slidesToShow) * slidesToShow;
    const nextSlideOffset = nextSlide + slidesToShow - totalItems;
    const nextSlideOffseted = nextSlide - (nextSlideOffset > 0 ? nextSlideOffset : 0);

    if (nextSlideOffseted !== currentSlide) {
      carousel.current.goToSlide(nextSlideOffseted);

      if (carousel.current.containerRef.current?.scrollLeft) {
        carousel.current.containerRef.current.scrollLeft = 0;
      }
    }
  };

  return (
    <div className="carousel" data-testid="carousel">
      <Carousel
        ref={carousel}
        responsive={threeCardsInRow ? RESPONSIVETHREE : RESPONSIVE}
        arrows={false}
        partialVisible={React.Children.count(children) > 1}
        renderButtonGroupOutside
        customButtonGroup={
          <CarouselControls {...{ carouselRef: carousel, updateAdditionalTransfrom }} />
        }
        additionalTransfrom={additionalTransfrom}
        beforeChange={(nextSlide, currentState) => {
          setIsCarouselMoving(true);
          updateAdditionalTransfrom({ nextSlide, currentState });
        }}
        afterChange={() => setIsCarouselMoving(false)}
        containerClass={classNames({
          'react-multi-carousel-list--single-item': React.Children.count(children) === 1,
        })}
      >
        {React.Children.map(children, (child, i) =>
          React.isValidElement(child)
            ? React.cloneElement(child, { isCarouselMoving, onFocus: () => onChildFocus(i) })
            : child
        )}
      </Carousel>
    </div>
  );
};

export default CarouselWrapper;
